import React from "react"

export const Checkout = () => {

    return (
        <div
            className="planne-widget planne-widget-checkout"
            data-widget-input-class="input"
            data-widget-continue-shopping-page="/"
            data-widget-skip-cart-step="true"
            data-widget-on-checkout-init="handleCheckoutInit"
            data-widget-on-sale-completion="handleSaleCompletion"
            data-widget-skip-resume-cart-page="true"
            data-widget-checkout-redirect-to="/minha-conta"
        ></div>
    )
}
