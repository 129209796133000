import React from 'react';

import { Checkout } from '@/components/Checkout';
import { IAppDataContent } from '@/interfaces/appdata';
import { DefaultLayout } from 'src/layouts/DefaultLayout';
import { ICategoryWithProducts } from '@/interfaces/products';

import './style.scss';

const CheckoutPage = ({ pageContext }: CheckoutProps) => {
  const { templateData } = pageContext.appData;
  const defaultLayoutData = { pageContext, templateData, title: '' };

  return (
    <DefaultLayout {...defaultLayoutData}>
      <div className="checkout-wrapper">
        <Checkout />
      </div>
    </DefaultLayout>
  );
};

interface CheckoutProps {
  pageContext: {
    appData: IAppDataContent;
    lang: string;
    categoriesWithProducts: ICategoryWithProducts[];
    currentPath: string;
    pathLang: string;
    widgetTarget: string;
  };
}

export default CheckoutPage;
